/* istanbul ignore file */

/**
 * @file This file contains the list of allowed products for ocs.
 */

import { EthereumNetworkMap } from 'cb-wallet-data/chains/AccountBased/Ethereum/EthereumChain';

export const testProducts = [
  { slicerId: 759, productIds: [2] },
  { slicerId: 831, productIds: [1, 2] },
  { slicerId: 345, productIds: [1] },
  { slicerId: 451, productIds: [55, 50, 17, 69, 70, 71] },
  { slicerId: 532, productIds: [1] },
];

// List of all the allowed products active on the shop -- includes REWARDS
export const allProducts = [
  { slicerId: 0, productIds: [12, 13] },
  { slicerId: 857, productIds: [1, 2, 3, 4, 5, 6] },
  { slicerId: 858, productIds: [1] },
  { slicerId: 859, productIds: [1] },
  { slicerId: 860, productIds: [1, 2] },
  { slicerId: 861, productIds: [1, 2, 3, 4] },
  { slicerId: 862, productIds: [1, 2, 3, 4] },
  { slicerId: 863, productIds: [1, 2, 3, 4, 5] },
  { slicerId: 864, productIds: [1, 2, 3, 4, 9] },
  { slicerId: 865, productIds: [1, 2, 3, 4, 5] },
  { slicerId: 866, productIds: [1, 2] },
  { slicerId: 867, productIds: [1, 2, 3] },
  { slicerId: 868, productIds: [1, 2, 3, 4, 5] },
  { slicerId: 869, productIds: [1] },
  { slicerId: 870, productIds: [1, 3, 4, 5, 6, 7] },
  { slicerId: 872, productIds: [1] },
  { slicerId: 873, productIds: [1, 2] },
  { slicerId: 874, productIds: [1] },
  { slicerId: 915, productIds: [1] },
  { slicerId: 916, productIds: [1, 2, 3, 4, 5] },
  { slicerId: 917, productIds: [1] },
  { slicerId: 1276, productIds: [1] },
  { slicerId: 1277, productIds: [1] },
  { slicerId: 1280, productIds: [1, 2] },
  { slicerId: 1337, productIds: [1] },
  { slicerId: 1338, productIds: [1, 2] },
  { slicerId: 1339, productIds: [1] },
  { slicerId: 1341, productIds: [1] },
  { slicerId: 1342, productIds: [1] },
  { slicerId: 1343, productIds: [1, 2, 3, 4, 5] },
  { slicerId: 1344, productIds: [1, 2, 3, 4] },
  { slicerId: 1345, productIds: [1, 2] },
  { slicerId: 1346, productIds: [1, 2, 3] },
  { slicerId: 1347, productIds: [1, 2, 3, 4] },
  { slicerId: 1403, productIds: [1, 2] },
  { slicerId: 1404, productIds: [1] },
  { slicerId: 1405, productIds: [1] },
  { slicerId: 1406, productIds: [1, 2] },
  {
    slicerId: 1408,
    productIds: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
      27, 28, 29, 30, 31, 32, 33,
    ],
  },
  { slicerId: 1423, productIds: [4] },
  { slicerId: 1430, productIds: [2] },
  { slicerId: 1478, productIds: [1, 2, 3] },
  { slicerId: 1479, productIds: [1, 2, 3] },
  {
    slicerId: 871,
    productIds: [
      // 1,
      6, 7, 8, 9, 11, 10,
    ],
  },
];

// IDs of the parterns' shops
export const partnersShops = [...new Set(allProducts.map(({ slicerId }) => slicerId))];

export const externalRewards = [
  {
    level: 1,
    title: 'Custom Punks - Exclusive Onchain Hockey and Football Jersey Trait',
    description:
      'Unlock the 2 new limited edition OCS hockey and football jerseys plus the 2 new limited edition OCS Pixel Sun backgrounds.',
    price: '-',
    link: 'https://www.custompunks.app/',
    image: 'https://go.wallet.coinbase.com/static/CP-OCS-Football-B-Pit.png',
    companyLogo: 'https://go.wallet.coinbase.com/static/replace%20image%20%282%29.png',
    companyName: 'Custom Punks',
    active: true,
  },
  {
    level: 2,
    title: 'Free Onchain Summer Custom Punks',
    description: 'Claim your free commemorative OCS Custom Punks NFT airdrop.',
    price: 'Free',
    link: 'https://custompunks.deform.cc/ocs-level2-claim',
    image: 'https://go.wallet.coinbase.com/static/image%20%2826%29.png',
    companyLogo: 'https://go.wallet.coinbase.com/static/replace%20image%20%282%29.png',
    companyName: 'Custom Punks',
    active: true,
  },
  {
    level: 3,
    title: 'Free Base.eth username',
    description: 'Claim your free Base.eth username and be part of the Base.eth community.',
    price: 'Free',
    link: 'https://www.base.org/name?utm_source=prizestore&utm_campaign=OCS',
    image: 'https://go.wallet.coinbase.com/static/image%20%2824%29.png',
    companyLogo: EthereumNetworkMap.whitelisted.BASE_MAINNET.chainImageUrl,
    companyName: 'Base',
    active: true,
  },
];

export const allRewards = [
  // { slicerId: 871, productId: 1, level: 4 },
  { slicerId: 871, productId: 6, level: 5 },
  { slicerId: 871, productId: 7, level: 6 },
  { slicerId: 871, productId: 8, level: 7 },
  { slicerId: 871, productId: 9, level: 8 },
  { slicerId: 871, productId: 11, level: 9 },
  { slicerId: 871, productId: 10, level: 10 },
];

export const allSlicerIds = [
  ...new Set([...allProducts, ...testProducts].map(({ slicerId }) => slicerId)),
];

export const freeShippingProducts = [
  // { slicerId: 871, productId: 1 },
  { slicerId: 871, productId: 6 },
  { slicerId: 871, productId: 7 },
  { slicerId: 871, productId: 8 },
  { slicerId: 871, productId: 9 },
  { slicerId: 871, productId: 10 },
  { slicerId: 871, productId: 11 },
  { slicerId: 1423, productId: 4 },
  { slicerId: 1430, productId: 2 },
];

export const restrictedToUSProducts = [{ slicerId: 1430, productId: 2 }];

/**
 * @notice assumes ethUsd is 1 -- no dynamic pricing for OCS
 */
export const ethUsd = 1;

export const ocsShopSupportEmail = 'support@luminode.io';

export function getRandomProducts(
  initialProducts: { slicerId: number; productIds: number[] }[],
  count?: number,
) {
  // Flatten the array into individual { slicerId, productId } pairs
  const flattened = initialProducts.flatMap((product) => {
    if (product.slicerId === 871) return []; // remove rewards from the list

    return product.productIds.map((productId) => ({
      slicerId: product.slicerId,
      productIds: [productId],
    }));
  });

  // Shuffle the flattened array
  const shuffled = flattened.sort(() => 0.5 - Math.random());

  const numberOfProducts = count || shuffled.length;

  // Select the first `count` elements
  const selected = shuffled.slice(0, numberOfProducts);

  return selected;
}
